var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`m-3 p-2 wrapper  wrapper--default`},[(!_vm.loading)?_c('h3',{staticClass:"text-center"},[_vm._v("Export documenten - "+_vm._s(_vm.name))]):_vm._e(),(_vm.loading)?_c('Loading'):_c('table',{class:`w100 table--default table__border--default`},[_c('thead',{class:`table__head--default`},[_c('tr',[_c('th',{staticClass:"table__head--text"},[_vm._v("Aflevering")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Item")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Merk & type")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Klant")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Land")]),_c('th',{staticClass:"table__head--text"},[_vm._v("BTW")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Borg")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Kwitantie")]),_c('th',{staticClass:"table__head--text"},[_vm._v("Opmerking")]),_c('th',{staticClass:"table__head--text"},[_vm._v("V")])])]),_c('tbody',_vm._l((_vm.export_documents),function(item,key){return _c('tr',{key:key,staticClass:"table__row--color"},[_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.afleverdatum))]),_c('td',{staticClass:"table__cell--default"},[_c('ImageHover',{attrs:{"itemnummer":item.nummer,"bu":item.bu}})],1),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.merktype))]),_c('td',{staticClass:"table__cell--default"},[(
              _vm.checkroles(
                ['deb_zien'],
                _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles
              )
            )?_c('router-link',{attrs:{"to":{
              name: 'klantpagina',
              params: { id: item.klant },
            }}},[_vm._v(" "+_vm._s(item.klantnaam)+" ")]):_vm._e()],1),_c('td',{staticClass:"table__cell--default"},[_vm._v(" "+_vm._s(item.landnaam)+" ")]),_c('td',{staticClass:"table__cell--default table__data--right"},[_vm._v(" € "+_vm._s(item.btw)+" ")]),_c('td',{staticClass:"table__cell--default table__data--right"},[_vm._v(" "+_vm._s(item.borg)+" ")]),_c('td',{staticClass:"table__cell--default table__data--right"},[_vm._v(" "+_vm._s(item.kwitantie)+" ")]),_c('td',{staticClass:"table__cell--default"},[_vm._v(" "+_vm._s(item.opmerkingen)+" ")]),(item.schip == '1')?_c('td',{staticClass:"table__cell--default table__data--right text-center"},[_c('i',{staticClass:"fas fa-ship"})]):_c('td',{staticClass:"table__cell--default table__data--right"})])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }