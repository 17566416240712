<template>
  <div :class="`m-3 p-2 wrapper  wrapper--default`">
    <h3 v-if="!loading" class="text-center">Export documenten - {{ name }}</h3>
    <Loading v-if="loading" />
    <table v-else :class="`w100 table--default table__border--default`">
      <thead :class="`table__head--default`">
        <tr>
          <th class="table__head--text">Aflevering</th>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Merk & type</th>
          <th class="table__head--text">Klant</th>
          <th class="table__head--text">Land</th>
          <th class="table__head--text">BTW</th>
          <th class="table__head--text">Borg</th>
          <th class="table__head--text">Kwitantie</th>
          <th class="table__head--text">Opmerking</th>
          <th class="table__head--text">V</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, key) in export_documents"
          :key="key"
          class="table__row--color"
        >
          <td class="table__cell--default">{{ item.afleverdatum }}</td>
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
          </td>
          <td class="table__cell--default">{{ item.merktype }}</td>
          <td class="table__cell--default">
            <router-link
              v-if="
                checkroles(
                  ['deb_zien'],
                  msalInstance.getAllAccounts()[0].idTokenClaims.roles
                )
              "
              :to="{
                name: 'klantpagina',
                params: { id: item.klant },
              }"
            >
              {{ item.klantnaam }}
            </router-link>
          </td>
          <td class="table__cell--default">
            {{ item.landnaam }}
          </td>
          <td class="table__cell--default table__data--right">
            € {{ item.btw }}
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.borg }}
          </td>
          <td class="table__cell--default table__data--right">
            {{ item.kwitantie }}
          </td>
          <td class="table__cell--default">
            {{ item.opmerkingen }}
          </td>
          <td v-if="item.schip == '1'" class="table__cell--default table__data--right text-center">
            <i class="fas fa-ship"></i>
          </td>
          <td v-else class="table__cell--default table__data--right">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import { msalInstance } from "vue-msal-browser";
import ImageHover from "@/components/ImageHover.vue";

export default {
  components: { Loading, ImageHover },
  data: () => ({
    export_documents: null,
    loading: null,
    name: null,
  }),
  created() {
    this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
    this.loading = true;
    this.getData(this.name);
  },
  methods: {
    getData(name) {
      request(`export-documents/${name}`, "GET").then(
        ({ export_documents }) => {
          this.export_documents = export_documents;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style></style>
